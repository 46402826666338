import Link from "next/link"

export default function CTA() {
  return (
    <div className="bg-pinto mt-24">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-16 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
          Start Building Profitably today.
          <br />
          Get your business on track and profitable quickly.
        </h2>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <Link
            href="https://calendly.com/pintofinancial/15min"
            className="rounded-md bg-pinto-yellow px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
          >
            Book a call with us <span aria-hidden="true">→</span>
          </Link>
        </div>
      </div>
    </div>

  )
}
    
// This was a rounder corner centered like CTA
/*<div className="bg-pinto">
      <div className="mx-auto max-w-7xl  sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-pinto px-6 py-24 text-center sm:px-16">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute bottom-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7487F7" />
                <stop offset={1} stopColor="#579186" />
              </radialGradient>
            </defs>
          </svg>
          <h2 className="mx-auto max-w-2xl text-3xl font-medium tracking-tight text-white sm:text-4xl">
            Start building profitably today.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
            Pinto gets your business on track and profitable for you quickly and keeps it there. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href="/register"
              className="transition duration-500 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white hover:text-pinto"
            >
              Get started
            </Link>
            <Link href="https://calendly.com/pintofinancial/15min" className="text-sm font-semibold leading-6 text-white transition duration-500 hover:text-gray-900 hover:underline">
              Book a call with us <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
    */